import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash-es/debounce'

/**
 * @param this.application
 * @param this.processIntersectionEntries
 * @param this.intersectionObserver
 * @param this.resetButtonTarget
 */

const event = new Event('submit.rails')

const objectData = (object, keys) => {
  keys.forEach(key => {
    delete object[key]
  })

  return object
}

const getFormJSON = (form) => {
  const data = new FormData(form)

  return Array.from(data.keys()).reduce((result, key) => {
    if (['authenticity_token', 'filters[category_id]'].includes(key)) return result

    let value
    if (result[key]) {
      value = data.getAll(key).slice(1).join(',')
      result[key] = value
      data.set(key, value)
      return result
    }

    value = data.get(key)
    if (!value || value.length === 0) return result

    result[key] = value
    data.set(key, value)
    return result
  }, {})
}

export default class extends Controller {
  static targets = ['filterList', 'filterForm', 'resetButton', 'triggerHide', 'triggerShow', 'toTop']

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.filterForm)
    document.addEventListener('turbo:load', () => setTimeout(this.initStates))
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.filterForm)
  }

  initStates = () => {
    document.querySelectorAll('[data-ref="select_block"]').forEach(selectBlock => {
      const input = selectBlock.parentElement.querySelector('input')
      if (!input) return

      const selects = [...selectBlock.querySelectorAll('sl-select')]
      selects.forEach(select => {
        const ids = select.dataset.selected
        select.value = ids.split(',')
        input.value = ids
      })
    })
    document.querySelectorAll('[data-ref="check_block"]').forEach(checkblock => {
      const input = checkblock.parentElement.querySelector('input')
      if (!input) return

      const checkboxes = [...checkblock.querySelectorAll('sl-checkbox')]
      const ids = checkboxes.map(c => { if (c.checked) return c.value }).filter(Boolean)
      input.value = ids.join(',')
    })
  }

  filterChange(e) {
    const wrapper = e.target.closest("[data-ref='filter_wrapper']")
    const checkboxes = [...wrapper.querySelectorAll('sl-checkbox')]
    let ids = []
    if (checkboxes.length > 0)
      ids = checkboxes.map(c => { if (c.checked) return c.value }).filter(Boolean)
    else
      ids = e.target.value

    wrapper.querySelector('input').value = ids.join(',')
    if (this.application.controllers.filter(c => c.StimulusReflex).length > 0) {
      this.resetButton.classList.remove('hidden')
      this.submit(wrapper.closest('form'))
    }
  }

  submit = debounce(form => {
    const loaders = [...document.querySelectorAll('#popular_loader')]
    if (loaders.length > 0) loaders.forEach(el => el.classList.remove('hidden'))
    console.log('FORM', form)
    // const data = Object.fromEntries(new FormData(form))
    const formData = getFormJSON(form)
    // const formData = objectData(data, ['authenticity_token', 'filters[category_id]'])
    console.log('formData', formData)
    if (Object.values(formData).filter(d => d.length).length === 0) this.resetButton.classList.add('hidden')
    form.dispatchEvent(event)
  }, 1500)

  reset(e) {
    const wrapper = e.target.parentElement
    const checkboxes = [...wrapper.querySelectorAll('sl-checkbox')]
    const selects = [...wrapper.querySelectorAll('sl-select')]

    checkboxes.forEach(checkbox => {
      checkbox.checked = false
      checkbox.removeAttribute('checked')
      checkbox.shadowRoot.querySelector('label').classList.remove('checkbox--checked')
    })
    selects.forEach(select => {
      select.dataset.selected = []
      select.value = ''
    })

    const form = wrapper.querySelector('form')
    // if (form) form.reset()
    this.initStates()
    form.dispatchEvent(event)
    this.resetButton.classList.add('hidden')
  }

  hideFilters(e) {
    this.triggerHandler(e)
    this.show.toggleAttribute('data-hidden')
    this.toTop.innerHTML = this.toTop.innerHTML.replace('наверх', '')
    this.cardsModification()
  }

  showFilters(e) {
    this.triggerHandler(e)
    this.hide.toggleAttribute('data-hidden')
    this.toTop.innerHTML = `${this.toTop.innerHTML} наверх`
    this.cardsModification()
  }

  triggerHandler(e) {
    this.filterList.toggleAttribute('data-opened')
    e.currentTarget.toggleAttribute('data-hidden')
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      const finderClass = entry.target.dataset.viewportEntranceToggleClass
      const button = this.element.getElementsByClassName(finderClass)[0]
      if (button)
       button.toggleAttribute('data-hidden')
    })
  }

  cardsModification() {
    this.catalogCards.forEach(card => card.toggleAttribute('data-hidden_image_view'))
  }

  get resetButton() { return this.resetButtonTarget }
  get filterForm() { return this.filterFormTarget }
  get filterList() { return this.filterListTarget }
  get hide() { return this.triggerHideTarget }
  get show() { return this.triggerShowTarget }
  get toTop() { return this.toTopTarget }
  get catalogCards() { return document.querySelectorAll('[data-ref="catalog_card"]') }
}
