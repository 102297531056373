import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash-es/debounce'
import Slider from 'bootstrap-slider'

let costSlider

const sliderOptions = {
  range: true,
  ticks_tooltip: true,
  tooltip_split: true,
  lock_to_ticks: true,
  tooltip: 'hide'
}

export default class extends Controller {
  static targets = ['costRangeBlock', 'rangeCost', 'rangeCostMin', 'rangeCostMax', 'loadMore']

  connect() {
    costSlider = new Slider(this.rangeCost, sliderOptions)
    costSlider.on('change', sliderValues => {
      const [min, max] = sliderValues.newValue
      this.rangeCostMin.textContent = min.toCurrency(2)
      this.rangeCostMax.textContent = max.toCurrency(2)
      this.hideUncosted(min, max)
    })

    // document.addEventListener('filters_applied', e => { if (costSlider) costSlider.refresh() })
    // document.addEventListener('loaded_more', _e => {
    //   console.log('loaded_more')
    //   this.stimulate('CostSlider#change')
    // })
    // this.productsList.addEventListener('cable-ready:after-insert-adjacent-html', () => this.loaded())
  }

  disconnect() {
    if (costSlider) costSlider.destroy()
  }

  hideUncosted = debounce((min, max) => {
    const lisId = this.costRangeBlock.dataset.reference_list_id
    const list = document.getElementById(lisId)
    if (!list) return false
    const items = [...list.children]
    items.forEach(item => {
      const price = parseFloat(item.dataset.product_price)
      if (price >= min && price <= max)
        item.classList.remove('hidden')
      else
        item.classList.add('hidden')
    })
  }, 1000)

  get costRangeBlock() { return this.costRangeBlockTarget }
  get rangeCost() { return this.rangeCostTarget }
  get rangeCostMin() { return this.rangeCostMinTarget }
  get rangeCostMax() { return this.rangeCostMaxTarget }
  get loadMore() { return this.loadMoreTarget }
}
