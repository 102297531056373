import { Controller } from '@hotwired/stimulus'
import DoubleGIS from '2gis-maps'

export default class extends Controller {
  static targets = ['contactsPage', 'mapContainer', 'mapPopup']

  initialize() {
    this.map = {
      markerCoordinates: [56.8397, 35.843],
      options: {
        desktopCenter: [56.8405, 35.8405],
        zoom: 16,
        animate: true,
        fullscreenControl: true,
        scrollWheelZoom: false,
        projectDetector: true
      }
    }

    this.gisMap = undefined
  }

  connect() {
    if (!!this.gisMap || this.mapContainer.innerHTML.length > 0) return

    setTimeout(() => this.buildMap(), 2000)
  }

  buildMap() {
    const center = this.map.options.desktopCenter
    const options = { ...this.map.options, center }

    DoubleGIS.then(() => {
      this.gisMap = DoubleGIS.map(this.mapContainer, options)
      DoubleGIS.marker(this.map.markerCoordinates)
        .addTo(this.gisMap)
        .bindPopup(this.mapPopup.innerHTML)
    })
  }

  get contactsPage() { return this.contactsPageTarget }
  get mapContainer() { return this.mapContainerTarget }
  get mapPopup() { return this.mapPopupTarget }
}
