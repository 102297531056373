import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = ['ribbonSlider']

  initialize() {
    window.ribbonSlider = undefined
  }

  connect() {
    let slider

    if (window.ribbonSlider)
      slider = window.ribbonSlider.rebuild()
    else
      if (this.ribbonSlider) {
        window.ribbonSlider = tns(this.sliderOptions)
        slider = window.ribbonSlider
      }

    slider.events.on('transitionEnd', () => setTimeout(this.refresh, 250))
  }

  disconnect() {
    window.ribbonSlider.destroy()
  }

  refresh() {
    let slider = window.ribbonSlider
    const { displayIndex } = slider.getInfo()

    slider = slider.rebuild()
    slider.goTo(displayIndex)
  }

  get ribbonSlider() { return this.ribbonSliderTarget }
  get sliderOptions() {
    return {
      container: this.ribbonSlider,
      nav: false,
      items: 5,
      startIndex: 0,
      loop: false,
      slideBy: 1,
      fixedWidth: 290,
      autoplay: false,
      arrowKeys: true,
      swipeAngle: true,
      mouseDrag: true,
      lazyload: true,
      responsive: {
        1700: {
          items: 4
        },
        800: {
          items: 3
        },
        480: {
          items: 2
        }
      }
    }
  }
}
