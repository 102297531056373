import { Controller } from '@hotwired/stimulus'
import notExistBind from 'src/application/not_exist_binding'

export default class extends Controller {
  connect() {
    // this.closeButton && this.closeButton.addEventListener('click', () => this.dialog.hide())
    this.dialog.addEventListener('sl-show', () => document.body.classList.add('modal_opened'))
    this.dialog.addEventListener('sl-after-show', () => {
      formsValidations()
      phoneMasking()
    })
    this.dialog.addEventListener('sl-hide', e => this.close(e))
    document.addEventListener('ajax:error', e => this.error(e))
  }

  signupWithCompany(e) { this.confirmListener(e) }
  phoneConfirmation(e) { this.confirmListener(e) }
  notExistRequest(e) { this.confirmListener(e) }
  proposalCreate(e) { this.confirmListener(e) }
  orderCreate(e) { this.confirmListener(e) }

  confirmListener(e) {
    e.target.form.addEventListener('ajax:success', e => this.result(e))
  }

  result(e) {
    let delay = 100
    if (this.dialog.open) {
      this.dialog.hide()
      delay *= 5
    }

    setTimeout(() => this.replaceContent(e), delay)
  }

  replaceContent(e) {
    this.dialog.setAttribute('label', e.target.dataset.modalTitle || '')
    if (!e.target.dataset.modalTitle || e.target.dataset.modalTitle.length === 0)
      this.dialog.setAttribute('no-header', true)
    if (e.target.dataset.noBackground)
      this.dialog.setAttribute('no-background', true)
    else
      this.dialog.removeAttribute('no-background')
    this.dialog.setAttribute('style', `--width: ${e.target.dataset.width || '30%'}`)
    const newModal = e.target.dataset.modalLabel
    const wrapper = this.dialogBody.getElementsByClassName(`${newModal}_content`)[0]
    const contentType = wrapper.dataset.type

    if (!this.currentModal || this.currentModal !== newModal) {
      this.dialog.setAttribute('data-current', newModal)

      for (const modal of this.modalTypes) {
        const modalForm = this.dialogBody.getElementsByClassName(`${modal}_content`)[0]
        modalForm && modalForm.classList.add('hidden')
      }

      if (wrapper.innerHTML.length > 0 && contentType === 'form') {
        const form = wrapper.getElementsByTagName('form')[0]
        if (form) {
          form.reset()
          for (const el of [...form.elements]) {
            el.classList.add('pristine')
            el.classList.remove('is-invalid')
            el.classList.remove('is-valid')
          }
        }
      } else {
        wrapper.innerHTML = e.detail[0].body.innerHTML

        for (const el of wrapper.getElementsByClassName('pristine'))
          if (el.value.length === 0 && el.required) el.classList.remove('is-valid')
      }

      wrapper.classList.remove('hidden')
    } else if (contentType === 'partial') {
      wrapper.innerHTML = e.detail[0].body.innerHTML
    }

    const formSender = e.target
    if (formSender.tagName === 'FORM') {
      formSender.classList.remove('was-validated')
      formSender.reset()
      for (const el of [...formSender.elements]) {
        el.classList.add('pristine')
        el.classList.remove('is-invalid')
        el.classList.remove('is-valid')
      }
    }

    // this.recaptchaRender()
    notExistBind(this.dialog)
    this.dialog.show()
  }

  close(e) {
    document.body.classList.remove('modal_opened')
    const modalContent = e.target.querySelector('[data-role="modal_content"]')
    if (!modalContent) return

    const afterHide = modalContent.querySelector('[data-after-hide-redirect]')
    if (!afterHide) return

    const { afterHideRedirect } = afterHide.dataset

    if (afterHideRedirect)
      window.location = afterHideRedirect
  }

  error(e) {
    if (!this.modalTypes) return
    if (this.modalTypes.includes(e.target.dataset.forModal)) {
      [...e.target.elements].forEach(el => {
        el.classList.remove('is-valid')
        el.removeAttribute('valid')
      })
      setTimeout(() => e.target.classList.remove('was-validated'), 1000)
      e.target.getElementsByClassName('form_errors')[0].innerHTML =
        `<span>${Object.values(e.detail[0]).join(' and ')}</span>`
    }
  }

  recaptchaRender() {
    const container = this.dialog.querySelector('.grecaptcha')
    if (!container) return

    const input = container.querySelector('.g-recaptcha')
    if (!input) return

    const { sitekey } = container.querySelector('.g-recaptcha').dataset
    if (!sitekey) return

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(sitekey, { action: input.attributes.action_name.value })
        .then(token => {
          input.value = token
        })
    })
  }

  get dialog() { return document.getElementById('modal_template') }
  get dialogBody() { return this.dialog.querySelectorAll("[data-role='modal_content']")[0] }
  get modalTypes() { return this.dialogBody.dataset.allowed_modal_types.split('|') }
  get currentModal() { return this.dialog.dataset.current }
  get closeButton() { return this.dialog.querySelector('sl-button[slot="footer"]') }
}
