import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['producerList']

  select(e) {
    const activated = e.target.dataset.active
    const producerId = parseInt(e.target.dataset.producer_id, 10)
    const listId = this.producerList.dataset.reference_list_id
    const list = document.getElementById(listId)
    if (!list) return false

    const items = [...list.children]
    if (activated) {
      delete e.target.dataset.active
      items.forEach(item => item.classList.remove('hidden'))
    } else {
      items.forEach(item => {
        e.target.dataset.active = true
        const producerIds = item.dataset.producers.split(',').map(id => parseInt(id, 10))
        if (producerIds.includes(producerId))
          item.classList.remove('hidden')
        else
          item.classList.add('hidden')
      })
    }
  }

  get producerList() { return this.producerListTarget }
}
