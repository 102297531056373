import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = ['slider', 'skeleton']

  initialize() {
    window.bannerSlider = undefined
  }

  connect() {
    if (window.bannerSlider)
      window.bannerSlider.rebuild()
    else
      if (this.slider && this.skeleton) window.bannerSlider = tns(this.sliderOptions)
  }

  disconnect() {
    window.bannerSlider.destroy()
  }

  get slider() { return this.sliderTarget }
  get skeleton() { return this.skeletonTarget }
  get sliderOptions() {
    return {
      container: this.slider,
      items: 4,
      slideBy: 1,
      autoplay: true,
      autoplayHoverPause: true,
      speed: 500,
      arrowKeys: true,
      autoHeight: true,
      edgePadding: 120,
      swipeAngle: true,
      mouseDrag: true,
      fixedWidth: 450,
      onInit: setTimeout(() => this.skeleton.classList.add('hidden'), 1500),
      responsive: {
        1100: {
          items: 3
        },
        480: {
          items: 1
        }
      }
    }
  }
}
