import { Controller } from '@hotwired/stimulus'
// import Stepper from 'bs-stepper'

let orderStepper

const stepMap = {
  check: 1,
  delivery: 2,
  payment: 3,
  payer_data: 4,
  finish: 5
}

export default class extends Controller {
  static targets = ['order', 'stepper']
  static values = { currentStep: String }

  connect() {
    // orderStepper = new Stepper(this.stepper)
    // orderStepper.to(stepMap[this.stepper.dataset.current_step])
  }

  get order() { return this.orderTarget }
  get stepper() { return this.stepperTarget }
  get step() { return window.location }
}
