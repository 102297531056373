import { application } from '@/javascript/controllers/application.js'
import StimulusReflex from 'stimulus_reflex'
import controller from './application_controller'

const controllers = import.meta.glob('./**/controller.js', { eager: true })

for (let path in controllers) {
  let module = controllers[path]
  let name = path
    .replace(/^\.\//, '')
    .replace(/\/controller\.js$/, '')
    .replace(/\//g, '_')

  application.register(name, module.default)
}

StimulusReflex.initialize(application, { controller, isolate: true, debug: true })
