import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = ['ribbonSlider']

  initialize() {
    window.ribbonSlider = undefined
  }

  connect() {
    if (window.ribbonSlider)
      window.ribbonSlider.rebuild()
    else
      if (this.ribbonSlider) window.ribbonSlider = tns(this.sliderOptions)
  }

  disconnect() {
    window.ribbonSlider.destroy()
  }

  get ribbonSlider() { return this.ribbonSliderTarget }
  get sliderOptions() {
    return {
      container: this.ribbonSlider,
      nav: false,
      items: 2,
      startIndex: 0,
      loop: false,
      slideBy: 1,
      autoplay: false,
      arrowKeys: true,
      swipeAngle: true,
      mouseDrag: true,
      lazyload: true,
      responsive: {
        800: {
          items: 3
        },
        480: {
          items: 2
        }
      }
    }
  }
}
