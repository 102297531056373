import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
  static targets = ['cartBlock']

  connect() {
    const popoverTrigger = this.cart.querySelector('[data-bs-toggle="popover"]')
    if (!popoverTrigger) return

    new Popover(popoverTrigger, {
      html: true,
      content: () => {
          const confirm = document.getElementById('cart_confirm').cloneNode(true)
          confirm.classList.remove('hidden')
          return confirm
      }
    })
  }

  get cart() { return this.cartBlockTarget }
}
