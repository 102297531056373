import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash-es/debounce'

const event = new Event('transform')

export default class extends Controller {
  static targets = ['quantityBlock', 'decrease', 'increase', 'quantity']

  decreaseQuantity(_e) {
    if (this.quantity === this.step) return

    const newValue = this.quantity - this.step
    this.quantityInput.innerText = newValue
    this.quantityInput.dataset.quantity = newValue
    this.transformCall()
  }

  increaseQuantity(_e) {
    const newValue = this.quantity + this.step
    this.quantityInput.innerText = newValue
    this.quantityInput.dataset.quantity = newValue
    this.transformCall()
  }

  transformCall = debounce(() => {
    this.quantityInput.dispatchEvent(event)
  }, 500)

  get component() { return this.quantityBlockTarget }
  get quantityInput() { return this.quantityTarget }
  get quantity() { return parseFloat(this.quantityInput.innerText) }
  get step() { return parseFloat(this.quantityInput.dataset.step) }
}
