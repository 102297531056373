import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['mainMenu', 'switcherClose', 'switcherOpen', 'footerInfoButtons']

  connect() {
    const onLoadClass = `${this.baseClass}--${this.positionOnLoad}`
    this.menu.classList.add(onLoadClass)

    if (this.positionOnLoad === 'closed') {
      this.switcherCloseTarget.classList.add('hidden')
      this.switcherOpenTarget.classList.remove('hidden')
    } else {
      this.switcherOpenTarget.classList.add('hidden')
      this.switcherCloseTarget.classList.remove('hidden')
    }
  }

  turn(_e) {
    this.modificators.forEach(modifier => {
      const currentClass = `${this.baseClass}--${modifier}`
      this.menu.classList.toggle(currentClass)
      if (this.menu.classList.contains(currentClass)) sessionStorage.mainMenuPosition = modifier
    })

    this.switchers.forEach(switcher => switcher.classList.toggle('hidden'))
    this.bodyModificators.forEach(modifier => document.body.classList.toggle(modifier))
  }

  goto(e) {
    const element = e.currentTarget.parentElement
    const klass = element.classList.value.split(' ')[0]
    element.classList.add(`${klass}--no_hover`)
  }

  hover(e) {
    const element = e.target.nextElementSibling
    if (!element) return false

    const klass = element.classList.value.split(' ')[0]
    element.classList.remove(`${klass}--no_hover`)
  }

  changeActive(event) {
    [...this.infoButtons.children].forEach(btn => delete btn.dataset.active)
    event.target.parentElement.dataset.active = 'true'
  }

  get menu() { return this.mainMenuTarget }
  get baseClass() { return `${this.identifier}--${this.menu.dataset.base_class}` }
  get modificators() { return this.menu.dataset.modificators.split('|') }
  get bodyModificators() { return this.menu.dataset.body_modificators.split('|') }
  get positionOnLoad() { return sessionStorage.mainMenuPosition || 'opened' }
  get switchers() { return [this.switcherCloseTarget, this.switcherOpenTarget] }
  get infoButtons() { return this.footerInfoButtonsTarget }
}
