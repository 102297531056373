import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['index', 'product']
  // static values = {
  //   active: String
  // }

  connect() {
    if (this.element.innerText.length === 0) return

    // const panel = this.element.getActiveTab().panel
    // this.element.show(panel)
  }
}
