import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = ['partnerSlider']

  initialize() {
    window.partnerSlider = undefined
  }

  connect() {
    if (window.partnerSlider)
      window.partnerSlider.rebuild()
    else
      if (this.partnerSlider) window.partnerSlider = tns(this.sliderOptions)
  }

  disconnect() {
    window.partnerSlider.destroy()
  }

  get partnerSlider() { return this.partnerSliderTarget }
  get sliderOptions() {
    return {
      container: this.partnerSlider,
      nav: false,
      items: 7,
      slideBy: 1,
      fixedWidth: 215,
      autoplay: true,
      arrowKeys: false,
      controls: false,
      swipeAngle: true,
      mouseDrag: true,
      autoplayHoverPause: true,
      speed: 500,
      autoplayTimeout: 8000,
      responsive: {
        1700: {
          items: 6
        },
        1400: {
          items: 5
        },
        800: {
          items: 4
        },
        480: {
          items: 3
        }
      }
    }
  }
}
