import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['buyBlock', 'buyErrors']

  create_provision (e) {
    this.errors.classList.add('hidden')
    if (String(e.detail[0].status).match(/2\d\d/)) {
      debugger
      this.stimulate('Product#change_unit')
    } else {
      this.errors.classList.remove('hidden')
    }
  }

  get errors () { return this.buyErrorsTarget }
}
