import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash-es/debounce'

const event = new Event('submit.rails')

export default class extends Controller {
  static targets = ['searchMain', 'searchForm', 'searchQueryField', 'searchResults', 'resetButton']

  connect() {
    this.searchQueryField.addEventListener('focus', e => {
      const box = e.target.parentNode
      e.target.setAttribute('data-opened', 'true')
      box.setAttribute('data-opened', 'true')
      e.target.removeAttribute('data-closed')
      box.removeAttribute('data-closed')
      this.searchResults.classList.remove('hidden')
    })
    this.searchQueryField.addEventListener('blur', e => {
      const box = e.target.parentNode
      e.target.setAttribute('data-closed', 'true')
      box.setAttribute('data-closed', 'true')
      e.target.removeAttribute('data-opened')
      box.removeAttribute('data-opened')
      setTimeout(() => this.searchResults.classList.add('hidden'), 500)
    })

    this.searchQueryField.addEventListener('keyup', () => this.submit())
  }

  submit = debounce(() => {
    this.searchForm.dispatchEvent(event)
  }, 1000)

  get searchMain() { return this.searchMainTarget }
  get searchForm() { return this.searchMain.querySelector('form') }
  get searchQueryField() { return this.searchForm.querySelector("input[type='search']") }
  get resetButton() { return this.resetButtonTarget }
  get searchResults() { return this.searchResultsTarget }
}
