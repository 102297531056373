import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['contactData']

  connect() {
    // this.contactDataTarget.addEventListener('sl-tab-show', e => {
    //   debugger
    //   const allInputs = e.target.querySelectorAll(`sl-tab-panel section input`)
    //   allInputs.forEach(input => {
    //     input.setAttribute('required', 'false')
    //   })
    //
    //   const inputs = e.target.querySelectorAll(`sl-tab-panel[name="${e.detail.name}"] section input`)
    //   inputs.forEach(input => {
    //     input.setAttribute('required', 'true')
    //   })
    // })

    this.allInputs.forEach(input => {
      input.addEventListener('change', e => this.duplicate(e))
    })
  }

  duplicate(_e) {
    this.allInputs.forEach(inputUpper => {
      const filledValue = inputUpper.value
      const id = inputUpper.id

      this.contactData.querySelectorAll(`#${id}`).forEach(input => {
        input.value = filledValue
        if (filledValue.length === 0)
          input.classList.add('pristine')
        else
          input.classList.remove('pristine')
      })
    })
  }

  get contactData() { return this.contactDataTarget }
  get allInputs() { return this.contactData.querySelectorAll('input') }
}
