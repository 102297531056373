import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['product', 'propertyTable', 'propertyPrimary', 'propertySecondary', 'unitSelector']

  tableOpen(e) {
    e.target.classList.add('hidden')
    this.propsSecondary.classList.remove('hidden')
  }

  tableClose(_e) {
    this.primaryButton.classList.remove('hidden')
    this.propsSecondary.classList.add('hidden')
  }

  unitChange(_e) {
    // const conversions = {}
    // const options = [...e.target.children]
    // options.map(el => { conversions[el.textContent] = el.value })
    // console.log(options)
  }

  createProvision(e) {
    console.log(e)
  }

  get product() { return this.productTarget }
  get propsTable() { return this.propertyTableTarget }

  get propsPrimary() { return this.propertyPrimaryTarget }
  get primaryButton() { return this.propsPrimary.querySelector('button') }

  get propsSecondary() { return this.propertySecondaryTarget }
  get secondaryButton() { return this.propsSecondary.querySelector('button') }

  get unitSelector() { return this.unitSelectorTarget }

  get baseClass() { return `${this.identifier}--${this.menu.dataset.base_class}` }
  get propertyModificators() { return this.menu.dataset.property_modificators.split('|') }
}
