import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []

  orderItemAction(e) {
    const classList = e.currentTarget.classList
    const closedKlass = [...classList].filter(klass => klass.includes('closed'))[0]
    const openedKlass = [...classList].filter(klass => klass.includes('opened'))[0]
    const innerList = e.currentTarget.querySelector('ul')
    const innerListClass = innerList.classList
    const innerClosedKlass = [...innerListClass].filter(klass => klass.includes('closed'))[0]
    const innerOpenedKlass = [...innerListClass].filter(klass => klass.includes('opened'))[0]

    if (closedKlass) {
      classList.remove(closedKlass)
      innerListClass.remove(innerClosedKlass)
      const toKlass = closedKlass.replaceAll('closed', 'opened')
      const innerToKlass = innerClosedKlass.replaceAll('closed', 'opened')
      classList.add(toKlass)
      innerListClass.add(innerToKlass)
    }

    if (openedKlass) {
      classList.remove(openedKlass)
      innerListClass.remove(innerOpenedKlass)
      const toKlass = openedKlass.replaceAll('opened', 'closed')
      const innerToKlass = innerOpenedKlass.replaceAll('opened', 'closed')
      classList.add(toKlass)
      innerListClass.add(innerToKlass)
    }
  }
}
