import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'productsList',
    'tumblerList',
    'tumblerTable',
    'tumblerGrid',
    'list',
    'headerList',
    'headerTable',
    'headerGrid'
  ]

  changeView(e) {
    const view = e.currentTarget.dataset.view
    this.list.dataset.itemType = view
    for (const tumbler of Object.values(this.tumblers))
      tumbler.removeAttribute('data-active')
    for (const header of Object.values(this.headers))
      header.setAttribute('data-hidden', true)
    e.currentTarget.toggleAttribute('data-active')
   this.headers[view].toggleAttribute('data-hidden')
  }

  get tumblers() {
    return {
      toRow: this.tumblerListTarget,
      toTable: this.tumblerTableTarget,
      toPlate: this.tumblerGridTarget
    }
  }

  get headers() {
    return {
      row: this.headerListTarget,
      table: this.headerTableTarget,
      plate: this.headerGridTarget
    }
  }

  get list() { return this.listTarget }
}
